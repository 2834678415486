import styled from 'styled-components';

import { Button, units } from '@calm-web/design-system';

import { ShowPreviewResults } from '../UploadEligibilityFileModal';

export const SuccessButton = styled(Button).attrs({ backgroundColor: 'buttonGreen' })`
	width: ${units(24)};
`;

export const StyledPreviewResults = styled(ShowPreviewResults)`
	${SuccessButton} {
		margin-left: ${units(1)};
	}
`;
