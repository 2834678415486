import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, TextButton, Button, fontWeight, FontWeights, units } from '@calm-web/design-system';

import TosSvg from 'icons/tos.svg';

export const ButtonWrapper = styled.div`
	display: inline-block;
	position: relative;
`;

export const FileInputButton = styled.input`
	width: 100%;
	height: 100%;
	/* All this nonsense is to get the cursor:pointer working properly on the VanityButton */
	opacity: 0;
	font-size: 0;
	text-indent: -100px;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	cursor: pointer;

	&:hover + button {
		&:before {
			opacity: 1;
		}
	}
`;

export const FileContainer = styled.div`
	display: flex;
	margin-bottom: ${units(3)};
`;

export const FileNameContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const NewFileLabel = styled.label`
	flex: 0 1 0;
	${fontWeight(FontWeights.Medium)};
	white-space: nowrap;
	margin-right: ${units(1)};
`;

export const FileIcon = styled(TosSvg)`
	path {
		fill: ${palette('blue2')};
	}
	flex: 0 0 auto;
	height: ${units(6)};
	width: auto;
`;

export const DifferentFileButton = styled(TextButton).attrs({ size: FontSizes.sm, hideUnderline: true })``;

export const VanityButton = styled(Button)`
	pointer-events: none;
	cursor: pointer;
`;

export const SegmentedVariationContainer = styled.div`
	${FileContainer} {
		align-items: center;
	}

	${FileIcon} {
		margin-right: ${units(1)};
	}
`;
