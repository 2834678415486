import { KeyboardEvent, ReactElement, ReactNode, useEffect } from 'react';
import { Column, ColumnInstance, usePagination, useTable } from 'react-table';

import { Loader } from '@calm-web/design-system';

import {
	ErrorWrapper,
	LoadingRow,
	PaginationButton,
	PaginationButtonWrapper,
	PaginationRow,
	TableComponent,
	TableHead,
	TableHeader,
	TableWrapper,
	TD,
	TH,
	TR,
} from './styles';

// This is all that react-table constrains to, so we match it here
// eslint-disable-next-line @typescript-eslint/ban-types
type RowData = object;

interface Props<D extends RowData> {
	columns: Array<Column<D>>;
	className?: string;
	data: Array<D>;
	fetchData?: (o: { pageIndex: number; pageSize?: number }) => void;
	loading: boolean;
	pageCount: number;
	error?: string;
	children?: ReactNode;
	pageSize?: number;
	forcePageIndex?: number;
	showHeader?: boolean;
	showRowLines?: boolean;
	horizontalInset?: boolean;
	alignHeadingsToText?: boolean;
	paginationBackgroundColor?: string;
	zebra?: boolean;
	cellVerticalAlign?: string;
	cellNoPadding?: boolean;
	dataTestId?: string;
	customHeaderAndRowHeight?: number;
	onRowClick?: (index: number) => void;
	customRowStyles?: string;
}

interface ColumnProps {
	width?: string | number;
	minWidth?: number;
	maxWidth?: number;
}

const NUM_PAGES_SHOWN = 5;
const PAGINATION_OFFSET = Math.ceil(NUM_PAGES_SHOWN / 2);

export default function Table<D extends RowData>({
	columns,
	className,
	data,
	fetchData,
	loading,
	pageCount: controlledPageCount,
	error: errorFetchingData,
	children,
	pageSize,
	forcePageIndex = 0,
	showHeader = true,
	showRowLines = true,
	horizontalInset = true,
	alignHeadingsToText = false,
	paginationBackgroundColor = 'gray1',
	zebra = false,
	cellVerticalAlign = 'baseline',
	cellNoPadding = false,
	dataTestId,
	customHeaderAndRowHeight,
	onRowClick,
	customRowStyles,
}: Props<D>): ReactElement {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		state: { pageIndex },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
			manualPagination: true,
			manualSortBy: true,
			autoResetPage: false,
			pageCount: controlledPageCount,
			pageSize,
		},
		usePagination,
	);
	const numPagesShown = NUM_PAGES_SHOWN;
	const paginationOffset = PAGINATION_OFFSET;

	// Listen for changes in pagination and use the state to fetch our new data
	useEffect(() => {
		if (fetchData) {
			fetchData({ pageIndex, pageSize });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageIndex, pageSize]);

	useEffect(() => {
		gotoPage(forcePageIndex);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forcePageIndex]);

	// Reference these based on what the user sees, not based on array indices
	const currentPage = pageIndex + 1;
	const lastPage = pageOptions.length + 1;

	function shouldShowPreviousEllipses(): boolean {
		if (pageOptions.length > numPagesShown) {
			if (currentPage > paginationOffset) {
				return true;
			}
		}
		return false;
	}

	function shouldShowNextEllipses(): boolean {
		if (pageOptions.length > numPagesShown) {
			if (currentPage < lastPage - paginationOffset) {
				return true;
			}
		}
		return false;
	}

	function getPaginatedSubset(): Array<number> {
		if (currentPage < paginationOffset) {
			return pageOptions.slice(0, numPagesShown);
		}
		if (currentPage < lastPage - paginationOffset) {
			return pageOptions.slice(currentPage - paginationOffset, currentPage + paginationOffset - 1);
		}
		return pageOptions.slice(lastPage - numPagesShown - 1, lastPage - 1);
	}

	function renderPageNumbers(): Array<ReactNode> {
		const options = getPaginatedSubset();
		return options.map(option => {
			return (
				<PaginationButton
					onClick={(): void => gotoPage(option)}
					selected={option === pageIndex}
					id={`pagination-${option}`}
					key={`pagination-${option}`}
				>
					{option + 1}
				</PaginationButton>
			);
		});
	}

	function getColumnProps(column: ColumnInstance<D>): ColumnProps {
		return {
			minWidth: column?.minWidth,
			width: column?.width,
			maxWidth: column?.maxWidth,
		};
	}

	// Render the UI for your table
	return (
		<TableComponent data-testid={dataTestId} className={className}>
			{children && <TableHeader>{children}</TableHeader>}
			{loading ? (
				<LoadingRow>
					<Loader />
				</LoadingRow>
			) : (
				<TableWrapper {...getTableProps()}>
					<TableHead
						show={showHeader}
						horizontalInset={horizontalInset}
						customHeaderAndRowHeight={customHeaderAndRowHeight}
					>
						{headerGroups.map(headerGroup => (
							<tr key={`header-${headerGroup?.id}`}>
								{headerGroup.headers.map(column => {
									const headerProps = column.getHeaderProps();
									const style = getColumnProps(column);
									return (
										<TH
											{...headerProps}
											key={headerProps.key}
											style={style}
											showBorder={showRowLines}
											alignToText={alignHeadingsToText}
											noPadding={cellNoPadding && alignHeadingsToText}
										>
											{column.render('Header')}
										</TH>
									);
								})}
							</tr>
						))}
					</TableHead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, index) => {
							prepareRow(row);
							const rowProps = row.getRowProps();

							const clickProps = onRowClick && {
								onClick: () => onRowClick(index),
								onKeyDown: (e: KeyboardEvent<HTMLTableRowElement>) => {
									if (onRowClick && e.key === 'Enter') onRowClick(index);
								},
								tabIndex: 0,
								role: 'link',
							};

							return (
								<TR
									{...rowProps}
									{...clickProps}
									key={rowProps.key}
									showBorder={showRowLines}
									horizontalInset={horizontalInset}
									zebra={zebra && index % 2 === 0}
									noPadding={cellNoPadding}
									customHeaderAndRowHeight={customHeaderAndRowHeight}
									$customRowStyles={customRowStyles}
								>
									{row.cells.map(cell => {
										const cellProps = cell.getCellProps();
										const style = getColumnProps(cell.column);
										return (
											<TD
												{...cellProps}
												key={cellProps.key}
												style={style}
												verticalAlign={cellVerticalAlign}
												noPadding={cellNoPadding}
											>
												{cell.render('Cell')}
											</TD>
										);
									})}
								</TR>
							);
						})}
						{errorFetchingData && (
							<tr>
								<td colSpan={99}>
									<ErrorWrapper>{errorFetchingData}</ErrorWrapper>
								</td>
							</tr>
						)}
					</tbody>
				</TableWrapper>
			)}
			{pageCount > 1 && (
				<PaginationRow backgroundColor={paginationBackgroundColor}>
					<PaginationButtonWrapper>
						<PaginationButton onClick={(): void => gotoPage(0)} disabled={!canPreviousPage}>
							first
						</PaginationButton>
						<PaginationButton onClick={(): void => previousPage()} disabled={!canPreviousPage}>
							prev
						</PaginationButton>
						{shouldShowPreviousEllipses() && <PaginationButton>...</PaginationButton>}
						{renderPageNumbers()}
						{shouldShowNextEllipses() && <PaginationButton>...</PaginationButton>}
						<PaginationButton onClick={(): void => nextPage()} disabled={!canNextPage}>
							next
						</PaginationButton>
						<PaginationButton onClick={(): void => gotoPage(pageCount - 1)} disabled={!canNextPage}>
							last
						</PaginationButton>
					</PaginationButtonWrapper>
				</PaginationRow>
			)}
		</TableComponent>
	);
}
