import { FC, FormEvent } from 'react';
import { useLocation } from 'react-router';

import StickySaveButton from '@/components/pages/Account/StickySaveButton';
import Plan from '@/components/pages/Plan';
import { useSubmitExistingHealthConfigForm } from '@/hooks/api/useHealthConfig';
import { usePermissions } from '@/hooks/auth';
import { useHealthConfigForm, useHealthConfigSubmitData } from '@/hooks/forms/useHealthConfigForm';
import { usePartnerForm, usePartnerSubmitData } from '@/hooks/forms/usePartnerForm';
import { useSubmitExistingPartnerForm } from '@/hooks/forms/useSubmitExistingPartnerForm';
import { useShouldShowEFFileFormatTemplate } from '@/hooks/useShouldShowEFFileFormatTemplate';
import { HealthConfig as HealthConfigType } from '@/types/health';
import { Partner } from '@/types/store/reducers';
import { isCalmHealthProductSKU } from '@/utils/SkuUtils';

import MentalHealthScreening from '../MentalHealthScreening';
import CalmHealthBranding from '../PartnerBranding/CalmHealthBranding';
import SubNav from '../SubNav';
import AccountDetails from './sections/AccountDetails';
import AccountReporting from './sections/AccountReporting';
import BrandingAndBenefits from './sections/BrandingAndBenefits';
import CalmAdmin from './sections/CalmAdmin';
import ChildPartners from './sections/ChildPartners';
import EmailsAndSurveys from './sections/EmailAndSurvey';
import HealthConfig from './sections/HealthConfig';
import Integration from './sections/Integration';
import { Wrapper } from './styles';

interface TabRouterProps {
	partner: Partner;
	healthConfig?: HealthConfigType;
}

const TabRouter: FC<TabRouterProps> = ({ partner, healthConfig }) => {
	const [hasValidPermissions, actions] = usePermissions();
	const { formProps, isPartnerFormDirty, hasTouchedPartnerForm } = usePartnerForm(partner);
	const { getPartnerSubmitData, showValidationErrors } = usePartnerSubmitData(formProps, partner);
	const [submitExistingPartnerForm, { loading }, getDtcImage] = useSubmitExistingPartnerForm(formProps);
	const {
		baseFormProps: healthConfigFormProps,
		eligibilityValidatorFormProps,
		hasChangedAny: isHealthConfigDirty,
		hasTouchedAny: isHealthConfigTouched,
	} = useHealthConfigForm(healthConfig);
	const { getHealthConfigSubmitData, showAdminValidationErrors } = useHealthConfigSubmitData(
		healthConfigFormProps,
		eligibilityValidatorFormProps,
	);
	const [submitExistingHealthConfigForm] = useSubmitExistingHealthConfigForm();

	const { hash } = useLocation();
	const activePage = hash === '' ? '#details' : hash;

	const isAccountManager = hasValidPermissions('client_id', [actions.READ]);
	const isCalmHealth = isCalmHealthProductSKU(partner?.product_sku);

	const shouldShowReportingTab =
		hasValidPermissions('reporting_delivery_method', [actions.READ]) &&
		isCalmHealth &&
		(!!healthConfig?.baa_data_retention || !!healthConfig?.enable_data_feed_override);
	const shouldShowEligibilityFileFormat = useShouldShowEFFileFormatTemplate();

	const onSubmit = async (e: FormEvent): Promise<void> => {
		e.preventDefault();
		if (showValidationErrors() || showAdminValidationErrors()) return;
		const partnerSubmitData = getPartnerSubmitData();

		try {
			await submitExistingPartnerForm(partnerSubmitData, partner);
			if (isCalmHealth) {
				const healthConfigSubmitData = getHealthConfigSubmitData();
				await submitExistingHealthConfigForm(
					shouldShowEligibilityFileFormat
						? {
								integration_type: healthConfigSubmitData.integration_type,
								client_id: healthConfigSubmitData.client_id,
								eligibility_file_format: healthConfigSubmitData.eligibility_file_format,
						  }
						: {
								integration_type: healthConfigSubmitData.integration_type,
								client_id: healthConfigSubmitData.client_id,
						  },
					partner.id,
				);
			}
		} catch (error) {
			//...
		}
	};

	return (
		<Wrapper>
			<SubNav partner={partner} shouldShowReportingTab={shouldShowReportingTab} />
			{activePage === '#details' && (
				<form onSubmit={onSubmit}>
					<AccountDetails isAccountManager={isAccountManager} partner={partner} formProps={formProps} />
					<StickySaveButton
						isFormDirty={isPartnerFormDirty}
						hasTouchedForm={hasTouchedPartnerForm}
						isLoading={loading}
						dataTestId="create-edit-partner-save-btn"
					>
						Save Configuration
					</StickySaveButton>
				</form>
			)}
			{activePage === '#integration' && <Integration />}
			{activePage === '#branding' &&
				(isCalmHealth ? (
					<CalmHealthBranding partner={partner} />
				) : (
					<form onSubmit={onSubmit}>
						<BrandingAndBenefits
							partner={partner}
							formProps={formProps}
							getDtcImage={getDtcImage}
							isShowingPartnerSave={isPartnerFormDirty}
						/>
						<StickySaveButton
							isFormDirty={isPartnerFormDirty}
							hasTouchedForm={hasTouchedPartnerForm}
							isLoading={loading}
							dataTestId="create-edit-partner-save-btn"
						>
							Save Configuration
						</StickySaveButton>
					</form>
				))}
			{activePage === '#emailAndSurvey' &&
				(isCalmHealth ? (
					<MentalHealthScreening partner={partner} />
				) : (
					<form onSubmit={onSubmit}>
						<EmailsAndSurveys
							partner={partner}
							formProps={formProps}
							isShowingPartnerSave={isPartnerFormDirty}
						/>
						{/* Partner Form Save Button */}
						<StickySaveButton
							isFormDirty={isPartnerFormDirty}
							hasTouchedForm={hasTouchedPartnerForm}
							isLoading={loading}
							dataTestId="create-edit-partner-save-btn"
						>
							Save Configuration
						</StickySaveButton>
					</form>
				))}
			{activePage === '#planAndPayment' && <Plan />}
			{activePage === '#reporting' && shouldShowReportingTab && <AccountReporting partner={partner} />}
			{activePage === '#admin' && isAccountManager && (
				<form onSubmit={onSubmit}>
					<CalmAdmin partner={partner} formProps={formProps} healthConfigFormProps={healthConfigFormProps} />
					<StickySaveButton
						isFormDirty={isPartnerFormDirty || isHealthConfigDirty}
						hasTouchedForm={hasTouchedPartnerForm || isHealthConfigTouched}
						isLoading={loading}
						dataTestId="create-edit-partner-save-btn"
					>
						Save Configuration
					</StickySaveButton>
				</form>
			)}
			{activePage === '#healthConfig' && isCalmHealth && <HealthConfig partnerId={partner.id} />}
			{activePage === '#childPartners' && <ChildPartners />}
		</Wrapper>
	);
};

export default TabRouter;
