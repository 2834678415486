/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
	pickFile: {
		id: 'users.upload.pickFile',
		defaultMessage: 'Pick a new file to upload',
		description: 'Button text to choose a file to upload when you have uploaded a file before',
	},
	newFileLabel: {
		id: 'users.upload.newFileLabel',
		defaultMessage: 'New File:',
		description: 'Label shown next to the name of the file you will be uploading',
	},
	loading: {
		id: 'users.upload.loading',
		defaultMessage: 'Loading…',
		description: 'Loading message',
	},
	pickDifferentFile: {
		id: 'users.upload.pickDifferentFile',
		defaultMessage: 'Pick a different file',
		description: 'Button text they can click to change their file selection',
	},
});
