import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from '@/hooks/api';
import { setBannerMessage } from '@/store/actions';
import { Admin } from '@/types/admin';
import { CalmError, getCalmErrorOrError, isCalmError } from '@/utils/apiRequest/errors';

export function useInvite(
	partnerId: string,
): [
	(
		adminEmail: string,
		adminFirstName: string,
		adminLastName: string,
		adminPhiValue?: boolean,
		isSftpAdmin?: boolean,
	) => Promise<Admin | void>,
	{ loading: boolean; error: CalmError | Error | undefined },
] {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const [error, setError] = useState<CalmError | Error | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);

	const showGenericErrorMessage = (): void => {
		dispatch(
			setBannerMessage({
				message: 'Failed to send email invite',
				flash: true,
				isError: true,
			}),
		);
	};

	async function sendInvite(
		adminEmail: string,
		adminFirstName: string,
		adminLastName: string,
		adminPHIAuthorized?: boolean,
		isSftpAdmin?: boolean,
	): Promise<Admin | void> {
		try {
			setLoading(true);
			const res = await apiRequest({
				endpoint: `partnerportal/${partnerId}/admin`,
				method: 'POST',
				body: {
					email: adminEmail,
					firstName: adminFirstName ?? '',
					lastName: adminLastName ?? '',
					phiAuthorized: adminPHIAuthorized,
					isSftpAdmin: isSftpAdmin,
				},
			});

			if (res?.status === 200) {
				dispatch(
					setBannerMessage({
						message: 'Admin email invite sent',
						flash: true,
						isError: false,
					}),
				);
				return res.data as Admin;
			} else {
				showGenericErrorMessage();
			}
		} catch (err) {
			setError(getCalmErrorOrError(err));
			if (isCalmError(err)) {
				const { data } = err;
				if (data?.error?.code === 'invalid_field') {
					dispatch(
						setBannerMessage({
							message: 'Error: Invalid email',
							flash: true,
							isError: true,
						}),
					);
				} else if (data?.error?.code === 'existing_email') {
					// TODO: BM - 10/27/2020 API is giving the wrong error here and currently doesn't allow sending
					// an invite email to a user that already exists
					dispatch(
						setBannerMessage({
							message:
								'It looks like that email is already registered. If you forgot your password, you can reset it using the link on the login page.',
							flash: true,
							isError: true,
						}),
					);
				} else {
					showGenericErrorMessage();
				}
			} else {
				showGenericErrorMessage();
			}
			throw err;
		} finally {
			setLoading(false);
		}
	}
	return [sendInvite, { loading, error }];
}
