import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Tooltip, Text, FontSizes } from '@calm-web/design-system';

import { useEligibilityUploads } from '@/hooks/api/useEligibility';

import messages from './messages';
import { InfoIcon } from './styles';

interface LastUploadInfoProps {
	partnerId: string;
}

interface LastUploadTooltipProps {
	usersAdded?: number;
	usersDeleted?: number;
	segmentsAdded?: number;
	segmentsDeleted?: number;
	segmentsUpdated?: number;
}
const emptyUpload = {
	delivery_mechanism: '',
	date: '',
	users_added: 0,
	users_deleted: 0,
	segments_added: 0,
	segments_deleted: 0,
	segments_updated: 0,
};

const TooltipContent: FC<LastUploadTooltipProps> = ({
	usersAdded,
	usersDeleted,
	segmentsAdded,
	segmentsDeleted,
	segmentsUpdated,
}) => {
	const { formatMessage } = useIntl();

	return (
		<>
			{usersAdded ? (
				<Text el="p" size={FontSizes.sm}>
					{formatMessage(messages.lastUploadUsersAdded, {
						usersAdded,
						b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
					})}
				</Text>
			) : null}
			{usersDeleted ? (
				<Text el="p" size={FontSizes.sm}>
					{formatMessage(messages.lastUploadUsersDeleted, {
						usersDeleted,
						b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
					})}
				</Text>
			) : null}
			{segmentsAdded ? (
				<Text el="p" size={FontSizes.sm}>
					{formatMessage(messages.lastUploadSegmentsAdded, {
						segmentsAdded,
						b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
					})}
				</Text>
			) : null}
			{segmentsDeleted ? (
				<Text el="p" size={FontSizes.sm}>
					{formatMessage(messages.lastUploadSegmentsDeleted, {
						segmentsDeleted,
						b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
					})}
				</Text>
			) : null}
			{segmentsUpdated ? (
				<Text el="p" size={FontSizes.sm}>
					{formatMessage(messages.lastUploadSegmentsUpdated, {
						segmentsUpdated,
						b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
					})}
				</Text>
			) : null}
		</>
	);
};

const LastUploadInfo: FC<LastUploadInfoProps> = ({ partnerId }) => {
	const { loading, data: eligibilityUploadData } = useEligibilityUploads(partnerId);

	const { formatMessage } = useIntl();
	const uploads = eligibilityUploadData?.eligibility_uploads;
	const latestUpload = uploads?.length ? uploads[0] : emptyUpload;
	const {
		delivery_mechanism,
		date,
		users_added,
		users_deleted,
		segments_added,
		segments_deleted,
		segments_updated,
	} = latestUpload;

	const canShowStats =
		Boolean(users_added) ||
		Boolean(users_deleted) ||
		Boolean(segments_added) ||
		Boolean(segments_deleted) ||
		Boolean(segments_updated);

	if (loading) return null;
	if (!canShowStats) {
		return (
			<Text el="p" size={FontSizes.sm} color="gray7">
				{formatMessage(messages.noPreviousSyncHeader)}
			</Text>
		);
	}

	const isEf = delivery_mechanism === 'partner_portal';

	if (isEf) {
		return (
			<Text el="p" size={FontSizes.sm} color="gray7">
				{formatMessage(messages.lastUploadHeader, {
					date: new Date(date || '').toLocaleString().toLowerCase(),
				})}
				<Tooltip
					content={
						<TooltipContent
							usersAdded={users_added}
							usersDeleted={users_deleted}
							segmentsAdded={segments_added}
							segmentsDeleted={segments_deleted}
							segmentsUpdated={segments_updated}
						/>
					}
				>
					<InfoIcon />
				</Tooltip>
			</Text>
		);
	}

	return (
		<Text el="p" size={FontSizes.sm} color="gray7">
			{formatMessage(messages.lastSyncHeader, {
				date: new Date(date || '').toLocaleString().toLowerCase(),
			})}
		</Text>
	);
};

export default LastUploadInfo;
