/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

// tiffany-todo: does the description in these need to be changed too?
export default defineMessages({
	referralTitle: {
		id: 'referral.TitleMessage',
		defaultMessage: 'Make the world a happier and healthier place',
		description: 'Title message of the Referral page',
	},
	referralSubtitle: {
		id: 'referral.SubtitleMessage',
		defaultMessage:
			'Share a lifetime of Calm with a fellow HR professional. If they bring Calm to their organization we’ll thank you with a $500 Amazon.com Gift Card* to spend on self care.',
		description: 'Subtitle message of the Referral page',
	},
	referral100Subtitle: {
		id: 'referral.Subtitle100Message',
		defaultMessage:
			'Share a lifetime of Calm with a friend or colleague. If they meet with our team for an introductory call we’ll thank you with a $100 Amazon.com Gift Card*.',
		description: 'Subtitle message of the Referral page',
	},
	referralNumbers: {
		id: 'referral.referralNumbers',
		defaultMessage: '9 out of 10',
		description: 'Quote message of the Referral page',
	},
	referralQuote: {
		id: 'referral.referralQuote',
		defaultMessage: 'Calm employers recommend Calm to other organizations',
		description: 'Quote message of the Referral page',
	},
	referralCopyLink: {
		id: 'referral.referralCopyLink',
		defaultMessage: 'Click to copy your link and share it with your HR network',
		description: 'Copy Link message of the Referral page',
	},
	referralTermsLink: {
		id: 'referral.termsLink',
		defaultMessage: 'terms and conditions',
		description: 'The text: terms and conditions',
	},
	howItWorks1: {
		id: 'referral.howItWorks1',
		defaultMessage:
			'Share your link with an HR professional – they’ll get a lifetime subscription to Calm Premium, free, when they book a demo.',
		description: 'First step describing how the program works',
	},
	howItWorks2: {
		id: 'referral.howItWorks2',
		defaultMessage:
			'If they sign up with Calm Business, you get a $500 Amazon.com Gift Card* to spend on self care.',
		description: 'Second step describing how the program works',
	},
	howItWorks2_100: {
		id: 'referral.howItWorks2_100',
		defaultMessage:
			'If they meet with our team for an introductory call, you get a $100 Amazon.com Gift Card*.',
		description: 'Second step describing how the program works',
	},
	finePrint: {
		id: 'referral.finePrint1',
		defaultMessage:
			'{giftValue} Amazon.com Gift Card* is available to customers in the USA{linebreak}* Restrictions apply, see <amazonLink>amazon.com/gc-legal</amazonLink> for terms and conditions.{linebreak}By referring another company, you agree to our <termsLink>Terms and Conditions</termsLink>',
		description: 'Second step describing how the program works',
	},
});
