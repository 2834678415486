import { defineMessages } from 'react-intl';

export default defineMessages({
	cardName: {
		id: 'plan.cardName',
		defaultMessage: 'Card: {cardname}',
		description: 'Card name for the plan section',
	},
	cardDetails: {
		id: 'plan.cardDetails',
		defaultMessage: 'Card details: •••• •••• •••• {cardnumber}',
		description: 'Card details for the plan section',
	},
	cardExp: {
		id: 'plan.cardExp',
		defaultMessage: 'exp: {cardexp}',
		description: 'Card exp for the plan section',
	},
	cardCvc: {
		id: 'plan.cardCvc',
		defaultMessage: 'cvc: {cardcvc}',
		description: 'Card cvc for the plan section',
	},
	bankAccount: {
		id: 'plan.bankAccount',
		defaultMessage: 'Bank account: ••••{bankaccount}',
		description: 'Bank account for the plan section',
	},
	routingNumber: {
		id: 'plan.routingNumber',
		defaultMessage: 'Routing number: ••••{routingnumber}',
		description: 'Routing number for the plan section',
	},
	billingAddress: {
		id: 'plan.billingAddress',
		defaultMessage: 'Billing address:',
		description: 'Billing address for the plan section',
	},
	addressDetails: {
		id: 'plan.addressDetails',
		defaultMessage: '{line1} {line2} {linebreak}{city}, {state}, {postal_code}',
		description: 'Address details for the plan',
	},
	changeDetailsLink: {
		id: 'plan.changeDetailsLink',
		defaultMessage: 'Change payment details',
		description: 'Change details link for the plan section',
	},
});
