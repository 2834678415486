import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, palette, prop, theme } from 'styled-tools';

import { fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

interface PaginationButtonProps {
	selected?: boolean;
	disabled?: boolean;
}

const ROW_MIN_HEIGHT = 40;

export const TableWrapper = styled.table`
	width: 100%;
	${fontWeight(FontWeights.Medium)};
	${fontSize(FontSizes.sm)};
	border-collapse: collapse;
`;

export const TableHead = styled.thead<{
	show: boolean;
	horizontalInset: boolean;
	customHeaderAndRowHeight?: number;
}>`
	height: ${p => (p.customHeaderAndRowHeight ? p.customHeaderAndRowHeight + 'px' : units(3))};
	width: 100%;
	text-align: left;
	${fontWeight(FontWeights.Medium)};
	${ifNotProp('show', 'visibility: collapse')};

	> tr {
		> th:first-child {
			padding-left: ${ifProp('horizontalInset', `${units(3)};`, 0)};
		}
		> th:last-child {
			padding-right: ${ifProp('horizontalInset', `${units(3)};`, 0)};
		}
	}

	* {
		${fontWeight(FontWeights.Medium)};
	}
`;

export const TH = styled.th<{ showBorder: boolean; alignToText?: boolean; noPadding?: boolean }>`
	${ifProp('showBorder', `border-bottom: 1px solid`)};
	border-color: ${palette('gray1')};
	padding: ${ifProp('noPadding', 0, ifProp('alignToText', `${units(1, 3, 1, 3)};`, `${units(1)};`))}};
`;

export const TR = styled.tr<{
	showBorder: boolean;
	horizontalInset: boolean;
	zebra?: boolean;
	noPadding?: boolean;
	customHeaderAndRowHeight?: number;
	$customRowStyles?: string;
}>`
	${ifProp('showBorder', `border-bottom: 1px solid`)};
	border-color: ${palette('gray1')};
	${ifProp(
		'zebra',
		css`
			background-color: ${palette('gray0')};
		`,
	)}

	td {
		padding: ${ifProp('noPadding', 0, `${units(1, 3)};`)};
		box-sizing: border-box;

		div {
			height: ${p => (p.customHeaderAndRowHeight ? p.customHeaderAndRowHeight + 'px' : 'inherit')};
		}

		&:last-child {
			padding-right: ${ifProp('horizontalInset', units(3), 0)};
		}
	}
	${p => p.$customRowStyles}
`;

export const LoadingRow = styled.div`
	height: ${ROW_MIN_HEIGHT * 5}px;
	align-items: center;
	justify-content: center;
	width: 100%;
	display: flex;

	svg {
		height: auto;
		width: 100%;
		max-width: 400px;
		circle {
			fill: ${palette('gray3')};
		}
	}
`;

export const TD = styled.td<{ verticalAlign?: string; noPadding?: boolean }>`
	vertical-align: ${prop('verticalAlign', 'baseline')};
	word-break: break-word;
`;

export const PaginationRow = styled.div<{ backgroundColor?: string }>`
	margin-top: ${units(1)};
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	height: ${units(7)};
	background: ${({ backgroundColor }) => palette(backgroundColor ?? 'gray1')};
	padding: ${units(1)};
	box-sizing: border-box;
`;

export const TableComponent = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	border-radius: 6px;
`;

export const PaginationButtonWrapper = styled.div`
	border: 1px solid ${palette('gray3')};
	border-radius: ${theme('layout.borderRadius')};
	overflow: hidden;
	height: 100%;
	margin: 0 auto;
	width: auto;
	display: block;

	button:not(:last-child) {
		border-right: 1px solid ${palette('gray3')};
	}
`;

export const PaginationButton = styled.button<PaginationButtonProps>`
	height: 100%;
	border: none;
	min-width: ${units(6)};
	cursor: pointer;
	background: ${ifProp('selected', palette('blue3'), palette('white'))};
	color: ${ifProp('disabled', palette('gray3'), palette('blue3'))};
	${ifProp('selected', 'color: white;')}
`;

export const ErrorWrapper = styled.div`
	display: flex;
	width: 100%;
	text-align: center;
	align-items: center;
	justify-content: center;
	min-height: 200px;
`;

export const TableHeader = styled.div`
	padding: ${units(3)};
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
`;
