import styled from 'styled-components';

import { fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

import { Subtitle } from '@/components/ui/CellTitle';

export const PromotionLabel = styled.label`
	${fontSize(FontSizes.sm)}
	${fontWeight(FontWeights.Medium)};
	margin-bottom: ${units(1)};
`;

export const PromotionColumnWrapper = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content: space-between;
`;

export const PartnershipConfigColumn = styled.div`
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	align-content: space-between;
	box-sizing: border-box;
	flex-direction: column;
	flex: 33%;
	align-items: flex-start;

	&:not(:last-child) {
		padding-right: ${units(2)};
	}
`;

export const OfferSubtitle = styled(Subtitle)`
	margin-top: ${units(1)};
	margin-bottom: 0;
`;

export const OfferTerms = styled.div`
	margin-top: ${units(1)};
	margin-bottom: 0;
`;

export const DurationInMonthsInput = styled.div`
	margin-top: ${units(1)};
	margin-bottom: 0;

	label {
		font-size: 1rem;
	}
`;
