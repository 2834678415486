import styled from 'styled-components';

import { maxWidth, units } from '@calm-web/design-system';

import PhoneOutlineSvg from 'icons/phone-outline.svg';

export const Wrapper = styled.div`
	position: relative;
	height: ${units(81.25)};
	width: ${units(37.5)};
	overflow: hidden;
	border-radius: ${units(5)};
	margin: 0 auto ${units(3)};

	${maxWidth('desktop')} {
		margin: 0 auto ${units(3)};
	}
`;

export const PhoneOutline = styled(PhoneOutlineSvg)`
	position: absolute;
	height: 100%;
`;
