export interface PlanDetails {
	coveredLives: number;
	costPerLife: string;
	total: string;
	expires: string;
}

export interface BillingDetails {
	name: string;
	brand?: string | undefined;
}

export enum PAYMENT_TYPE {
	CARD = 'card',
	US_BANK_ACCOUNT = 'us_bank_account',
}

export interface PaymentInfo {
	brand?: string;
	last4?: string;
	exp_month?: string;
	exp_year?: string;
	type?: PAYMENT_TYPE;
	billing_details?: {
		address?: {
			city?: string;
			country?: string;
			line1?: string;
			line2?: string;
			postal_code?: string;
			state?: string;
		};
	};
	card?: {
		brand?: string;
		last4?: string;
		exp_month?: string;
		exp_year?: string;
	};
	us_bank_account?: {
		routing_number?: string;
		last4?: string;
	};
}
