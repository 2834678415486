import { FC, useCallback, FormEvent } from 'react';

import { Card, FormInput, FormSelect, Loader, Text } from '@calm-web/design-system';

import StickySaveButton from '@/components/pages/Account/StickySaveButton';
import CellTitle from '@/components/ui/CellTitle';
import useHealthConfig, { useSubmitExistingHealthConfigForm } from '@/hooks/api/useHealthConfig';
import { usePermissions } from '@/hooks/auth';
import {
	FieldNames,
	useHealthReportingConfigForm,
	useHealthReportingSubmitData,
} from '@/hooks/forms/useHealthReportingConfigForm';
import { HealthConfig } from '@/types/health';
import { Partner } from '@/types/store/reducers';

import { CellSubtitle } from '../../../HealthSponsorshipDetail/styles';
import { SelectContainer, StyledFormSelect, LabelText } from './styles';

interface AccountReportingProps {
	healthConfig: HealthConfig;
	partnerId: string;
}

const AccountReportingInner: FC<AccountReportingProps> = ({ healthConfig, partnerId }) => {
	const { formProps, hasChangedAny, hasTouchedAny } = useHealthReportingConfigForm(healthConfig);
	const { getHealthReportingSubmitData, showValidationErrors } = useHealthReportingSubmitData(formProps);
	const [submitExistingHealthConfigForm, { loading }] = useSubmitExistingHealthConfigForm();
	const [hasValidPermissions, actions] = usePermissions();

	const onSubmit = useCallback(
		async (e: FormEvent): Promise<void> => {
			e.preventDefault();
			if (showValidationErrors()) return;
			try {
				await submitExistingHealthConfigForm(getHealthReportingSubmitData(), partnerId);
				formProps.resetAllDirtyStates();
			} catch (error) {
				//...
			}
		},
		[
			partnerId,
			showValidationErrors,
			submitExistingHealthConfigForm,
			formProps,
			getHealthReportingSubmitData,
		],
	);

	const deliveryOptions = [
		{ value: 'none', label: 'None' },
		{ value: 'sftp', label: 'SFTP' },
		{ value: 'secure_portal', label: 'Secure Portal' },
	];
	const feedOptions = [
		{ value: 'never', label: 'Never' },
		{ value: 'P1D', label: 'Daily' },
		{ value: 'P7D', label: 'Weekly' },
		{ value: 'P1M', label: 'Monthly' },
	];
	const dataFeedsArray: { name: FieldNames; display: string }[] = [
		{ name: 'registered_users', display: 'Registered User Data Feed' },
		{ name: 'logins', display: 'Logins Data Feed' },
		{ name: 'checkins', display: 'Checkins Data Feed' },
		{ name: 'clinical_programs', display: 'Clinical Programs Data Feed' },
		{ name: 'health_sessions', display: 'Sessions Data Feed' },
		{ name: 'referrals', display: 'Recommendations Data Feed' },
		{ name: 'survey_responses', display: 'Survey Responses Data Feed' },
		{ name: 'conditions', display: 'Conditions Data Feed' },
	];
	return (
		<form onSubmit={onSubmit}>
			<Card>
				<CellTitle showAdminFlag>Data Feeds and Reporting Configurations</CellTitle>
				<CellSubtitle>
					Calm Health allows our customers to choose how they would like to receive reports. If you select
					SFTP or Secure Portal for data feed delivery, please reach out to Data Engineering to coordinate.
				</CellSubtitle>
				<FormSelect
					aria-label="Delivery Method"
					options={deliveryOptions}
					placeholder="Delivery"
					disabled={!hasValidPermissions('reporting_delivery_method', [actions.UPDATE])}
					{...formProps.bindWithErrorProps('delivery', 'text')}
				></FormSelect>
				<FormInput
					label="Technical POC Email"
					disabled={!hasValidPermissions('reporting_technical_poc_email', [actions.UPDATE])}
					{...formProps.bindWithErrorProps('technical_contact', 'text')}
				></FormInput>
				<CellSubtitle>
					Data feeds can be sent at a variety of intervals. Indicate how often reach report should be
					received. Weekly data feeds will be delivered every Monday. Monthly reports will be delivered on the
					first of the month.
				</CellSubtitle>
				{dataFeedsArray.map(feed => {
					return (
						<SelectContainer key={feed.display}>
							<LabelText>{feed.display}</LabelText>
							<StyledFormSelect
								aria-label={`${feed.display} Frequency`}
								placeholder="Frequency"
								options={feedOptions}
								disabled={!hasValidPermissions('reporting_data_feeds', [actions.UPDATE])}
								{...formProps.bindWithErrorProps(feed.name, 'text')}
							/>
						</SelectContainer>
					);
				})}
			</Card>
			<StickySaveButton
				isFormDirty={hasChangedAny}
				hasTouchedForm={hasTouchedAny}
				isLoading={loading}
				dataTestId="create-edit-partner-health-reporting-save-button"
			>
				Save Configuration
			</StickySaveButton>
		</form>
	);
};

const AccountReporting = ({ partner }: { partner: Partner }): React.ReactElement => {
	const { data: healthConfig, loading } = useHealthConfig({ partnerId: partner.id });
	if (healthConfig) {
		return <AccountReportingInner healthConfig={healthConfig} partnerId={partner.id} />;
	}
	if (loading) {
		return <Loader />;
	}
	return <Text>Sorry, there was an issue loading your configuration</Text>;
};

export default AccountReporting;
