import { ReactElement, SetStateAction } from 'react';
import { useIntl } from 'react-intl';

import { Modal } from '@calm-web/design-system';

import { useReactivateSubscription } from '@/hooks/api/useSubscription';
import { CalmError } from '@/utils/apiRequest/errors';

import { BillingDetails, PaymentInfo, PlanDetails } from '../types';
import Loading from './Loading';
import Pending from './Pending';
import Success from './Success';
import messages from './messages';

interface Props {
	onModalDismiss: () => void;
	planDetails: PlanDetails;
	billingDetails: BillingDetails;
	setBillingDetails: (details: SetStateAction<BillingDetails>) => void;
	getPaymentInfo: () => Promise<PaymentInfo>;
	paymentInfo: PaymentInfo;
	paymentError: CalmError | undefined;
	setShowChangeModal: (showChangeModal: SetStateAction<boolean>) => void;
}

export default function ReactivatePlanModal({
	onModalDismiss,
	planDetails,
	billingDetails,
	setBillingDetails,
	getPaymentInfo,
	paymentInfo,
	paymentError,
	setShowChangeModal,
}: Props): ReactElement {
	const { formatMessage } = useIntl();
	const [onReactivatePlan, { loading: isReactivating, error: reactivationError, hasReactivated }] =
		useReactivateSubscription();

	const reactivationUi = hasReactivated ? (
		<Success onModalDismiss={onModalDismiss} planDetails={planDetails} />
	) : (
		<Pending
			planDetails={planDetails}
			reactivationError={reactivationError}
			isReactivating={isReactivating}
			onReactivatePlan={async () => {
				await onReactivatePlan({ name: billingDetails.name });
				await getPaymentInfo();
			}}
			billingDetails={billingDetails}
			setBillingDetails={setBillingDetails}
			paymentInfo={paymentInfo}
			paymentError={paymentError}
			setShowChangeModal={setShowChangeModal}
		/>
	);

	return (
		<Modal
			isOpen
			closeModal={onModalDismiss}
			title={formatMessage(messages.reactivateTitle)}
			data-testid="reactivation-modal"
		>
			{isReactivating ? <Loading /> : reactivationUi}
		</Modal>
	);
}
