import { FC, ReactElement, SetStateAction } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@calm-web/design-system';

import StripePaymentElementForm from '@/components/pages/Teams/PaymentWithPaymentElement/PaymentForm/StripePaymentElementForm';
import { useUser } from '@/hooks/store';
import { ApiError, CalmError, isCalmError } from '@/utils/apiRequest/errors';

import PaymentDetails from '../../PaymentDetails';
import { BillingDetails, PaymentInfo, PlanDetails } from '../../types';
import messages from './messages';
import {
	ErrorMessage,
	PlanDetail,
	PlanRow,
	SectionContainer,
	Title,
	Total,
	TotalRow,
	Container,
} from './styles';

function ReactivationErrorMessage({
	reactivationError,
	paymentError,
}: {
	reactivationError?: Error | ApiError;
	paymentError: unknown | undefined;
}): ReturnType<FC> {
	const { formatMessage } = useIntl();
	if (paymentError) {
		return <ErrorMessage>{formatMessage(messages.invalidPaymentError)}</ErrorMessage>;
	}

	if (!reactivationError || !isCalmError(reactivationError)) {
		return null;
	}

	const {
		data: {
			error: { code },
		},
	} = reactivationError;
	const INVALID_STRIPE_REQUEST = 'stripe_invalid_request';
	const CALM_B2B_INVALID_UPDATE = 'b2b_self_serve_invalid_update';

	if (code === INVALID_STRIPE_REQUEST || code === CALM_B2B_INVALID_UPDATE) {
		return <ErrorMessage>{formatMessage(messages.invalidPaymentError)}</ErrorMessage>;
	}

	return <ErrorMessage>{formatMessage(messages.planReactivationError)}</ErrorMessage>;
}

export interface Props {
	planDetails: PlanDetails;
	billingDetails: BillingDetails;
	setBillingDetails: (details: SetStateAction<BillingDetails>) => void;
	reactivationError: Error | ApiError | undefined;
	isReactivating: boolean;
	onReactivatePlan: () => Promise<void>;
	paymentInfo: PaymentInfo;
	paymentError: CalmError | undefined;
	setShowChangeModal: (showChangeModal: SetStateAction<boolean>) => void;
}

export default function Pending({
	planDetails,
	reactivationError,
	isReactivating,
	onReactivatePlan,
	billingDetails,
	setBillingDetails,
	paymentInfo,
	paymentError,
	setShowChangeModal,
}: Props): ReactElement {
	const { formatMessage } = useIntl();
	const { user } = useUser();

	/**
	 * When reactivating an expired subscription, we are actually generated a new
	 * self-serve subscription on the backend. This process requires a stripe token,
	 * so user must provide their Payment Information on the UI.
	 */

	const isExpired = new Date(planDetails.expires) < new Date();
	const showPaymentInput = isExpired || !!reactivationError || !!paymentError || !paymentInfo.last4;

	return (
		<>
			<Title>{formatMessage(messages.planDetails)}</Title>
			<SectionContainer>
				<PlanRow>
					<PlanDetail>
						{formatMessage(messages.coveredLives, {
							coveredLives: planDetails.coveredLives,
						})}
					</PlanDetail>
					<PlanDetail>{planDetails.coveredLives}</PlanDetail>
				</PlanRow>

				<PlanRow>
					<PlanDetail>{formatMessage(messages.costPerLife)}</PlanDetail>
					<PlanDetail>{planDetails.costPerLife}</PlanDetail>
				</PlanRow>

				<TotalRow>
					<Total>{formatMessage(messages.total)}</Total>
					<Total>{planDetails.total}</Total>
				</TotalRow>
			</SectionContainer>
			<Title>{formatMessage(messages.paymentDetails)}</Title>
			{showPaymentInput ? (
				<Container>
					<StripePaymentElementForm fullName={user?.name} />
				</Container>
			) : (
				<SectionContainer>
					<PaymentDetails
						paymentInfo={paymentInfo}
						paymentError={paymentError}
						setShowChangeModal={setShowChangeModal}
						isSubscriptionExpired={isExpired}
					/>
				</SectionContainer>
			)}
			<ReactivationErrorMessage reactivationError={reactivationError} paymentError={paymentError} />
			<Button
				backgroundColor="blue3"
				isLoading={isReactivating}
				onPress={onReactivatePlan}
				data-testid="do-reactivate"
			>
				{formatMessage(messages.reactivateButton)}
			</Button>
		</>
	);
}
