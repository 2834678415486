import styled from 'styled-components';

import { fontSize, FontSizes, fontWeight, FontWeights, units } from '@calm-web/design-system';

export const DetailsContainer = styled.div`
	flex: 1 0 50%;
	${fontWeight(FontWeights.Medium)};
	${fontSize(FontSizes.base)};
`;

export const PaymentDetailsContainer = styled.div`
	display: flex;
	margin-bottom: ${units(1)};
`;

export const CardDetail = styled.div`
	margin-right: ${units(2)};
`;

export const BillingAddress = styled.div`
	margin-right: ${units(1)};
`;

export const AddressDetails = styled.div`
	margin-right: ${units(2)};
`;

export const Subtitle = styled.div`
	margin-bottom: ${units(1)};
`;

export const Icon = styled.img`
	margin-left: ${units(2)};
`;
