import { FC, forwardRef, ReactElement, Ref, useLayoutEffect, useRef, useState } from 'react';
import { animated, useSprings } from 'react-spring';

import Circle1 from 'icons/confetti-circle-01.svg';
import Circle2 from 'icons/confetti-circle-02.svg';
import Circle3 from 'icons/confetti-circle-03.svg';
import Circle4 from 'icons/confetti-circle-04.svg';
import Rectangle1 from 'icons/confetti-rectangle-01.svg';
import Rectangle2 from 'icons/confetti-rectangle-02.svg';
import Rectangle3 from 'icons/confetti-rectangle-03.svg';
import Rectangle4 from 'icons/confetti-rectangle-04.svg';
import Triangle1 from 'icons/confetti-triangle-01.svg';
import Triangle2 from 'icons/confetti-triangle-02.svg';
import Triangle3 from 'icons/confetti-triangle-03.svg';
import Triangle4 from 'icons/confetti-triangle-04.svg';

import { Background } from './styles';

interface Props {
	bgSize?: string;
}

const confettiElements = [
	Rectangle1,
	Rectangle2,
	Rectangle3,
	Rectangle4,
	Circle1,
	Circle2,
	Circle3,
	Circle4,
	Triangle1,
	Triangle2,
	Triangle3,
	Triangle4,
];

type AnimatedElements = Array<ReactElement>;

const Confetti = ({ bgSize }: Props, ref?: Ref<HTMLDivElement>): ReturnType<FC> => {
	const confettiCount = Math.max(Math.min(Math.ceil(window.innerWidth / 10), 200), 40);
	const animationMaxLenght = confettiCount / 140;
	const [renderElements, setRenderElements] = useState<AnimatedElements>([]);
	const bgRef = useRef<HTMLDivElement>(null);
	const [springs] = useSprings(confettiCount, index => {
		const animationDelay = animationMaxLenght * index;
		const randomX = Math.random() * 100;
		const randomY = Math.random() * 100;
		const randomRotation = Math.floor(Math.random() * 180);
		return {
			from: {
				top: '150%',
				left: '50%',
				opacity: 0,
				upwards: 100,
				transform: `rotate(0deg)`,
			},
			to: {
				top: `${randomY}%`,
				left: `${randomX}%`,
				opacity: 1,
				upwards: 0,
				transform: `rotate(${randomRotation}deg)`,
			},
			delay: animationDelay,
			config: { mass: 1, tension: 100, friction: 15 },
		};
	});

	useLayoutEffect(() => {
		// Generate random confetti elements
		const elements = [] as AnimatedElements;
		springs.forEach((props, index) => {
			const randomElementIndex = Math.floor(Math.random() * confettiElements.length);
			const ConfettiElement = confettiElements[randomElementIndex];
			elements.push(
				<animated.div
					key={index}
					style={{
						...props,
						position: 'absolute',
					}}
				>
					<ConfettiElement />
				</animated.div>,
			);
		});

		setRenderElements(elements);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/*
<FadeIn style={{
  left: randomXPos,
  top: randomYPos,
  opacity: '0',
  animationDelay: `${randomAnimationLength}s`,
}}>
  <ConfettiElement />
</FadeIn>
  */

	return (
		<div ref={ref}>
			<Background ref={bgRef} bgSize={bgSize}>
				{renderElements}
			</Background>
		</div>
	);
};

export default forwardRef(Confetti);
