import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import {
	ButtonWrapper,
	TextButton,
	SecondaryButton,
	maxWidth,
	FontSizes,
	fontSize,
	fontWeight,
	FontWeights,
	units,
} from '@calm-web/design-system';

import { boxShadow } from '@/utils/ui/boxShadow';

export const Title = styled.div`
	${fontSize(FontSizes.lg)};
	margin-bottom: ${units(2)};
`;

export const Subtitle = styled.div`
	${fontWeight(FontWeights.Medium)};
`;

export const PlanDetail = styled.div`
	${fontWeight(FontWeights.Medium)};
	${fontSize(FontSizes.base)};
	margin-bottom: ${units(1)};
	display: flex;
	align-items: center;
`;

export const CancelButton = styled(SecondaryButton)`
	color: ${palette('heartRed')};
`;

export const PlanContainer = styled.div`
	flex: 1 0 50%;
	margin-right: ${units(1)};
`;

export const PrimaryTextButton = styled(TextButton).attrs({ hideUnderline: true })`
	${fontWeight(FontWeights.Demi)};
	${fontSize(FontSizes.sm)};
`;

export const LoadingContainer = styled.div`
	width: 100%;
	height: 80px;

	svg {
		circle {
			fill: ${palette('gray5')};
		}
	}
`;

export const PaymentContainer = styled.div`
	flex: 1 0 50%;
`;

export const LoaderContainer = styled.div`
	width: 250px;
	margin: 0 auto;
	margin-top: ${theme('navigation.height')}px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	margin-top: ${units(2)};
	${ButtonWrapper} + ${ButtonWrapper} {
		margin-left: ${units(1)};
	}
`;

export const TextBlock = styled.div`
	${fontSize(FontSizes.sm)}
`;

export const SectionContainer = styled.div`
	display: flex;
	background: ${palette('white')};
	border: 1px solid ${palette('gray1')};
	box-sizing: border-box;
	${boxShadow}
	border-radius: 4px;
	padding: ${units(3)};
	width: 100%;
	color: ${palette('gray7')};
	margin-bottom: ${units(3)};
	gap: ${units(1)};

	${ButtonContainer} + ${TextBlock} {
		margin-top: ${units(2)};
	}

	${Subtitle} {
		margin-bottom: ${units(2)};
	}

	${maxWidth('tablet')} {
		flex-wrap: wrap;
		gap: ${units(4)};
	}
`;

export const WebLink = styled.a`
	color: ${palette('blue3')};
	text-decoration: underline;
`;

export const SmallText = styled.div`
	${fontWeight(FontWeights.Medium)};
	${fontSize(FontSizes.base)};
	color: ${palette('gray8')};
	margin-left: ${units(1)};
`;
