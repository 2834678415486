import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import {
	Card,
	FontSizes,
	Text,
	fontSize,
	maxWidth,
	fontWeight,
	FontWeights,
	units,
} from '@calm-web/design-system';

import Table from '@/components/ui/Table';

import CircleMinus from 'icons/circle-minus.svg';
import InfoOutline from 'icons/info-outline.svg';

export const CircleMinusStyled = styled(CircleMinus)`
	height: ${units(3)};
	width: ${units(3)};
	padding: 0;
	path {
		fill: ${palette('heartRed')} !important;
	}
`;

export const EmailWrapper = styled.div``;

export const TableContainer = styled.div`
	max-width: ${theme('layout.mainMaxWidth')};
`;

export const TableStyled: typeof Table = styled(Table)`
	tr {
		td,
		th {
			&:last-child {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
`;

export const HeaderContainer = styled(Card)`
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
	margin-bottom: 0;
	border-bottom: 0;
	padding: ${units(4, 2, 2, 2)};
`;

export const HeaderRow = styled.div<{ $isSelfServe: boolean }>`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: ${units(3)};
	gap: ${units(2)};
`;

export const ButtonCell = styled.div`
	display: flex;
	justify-content: end;
`;

export const ButtonGroup = styled.div`
	display: flex;
	align-items: center;
	gap: ${units(2)};
	${maxWidth('desktop')} {
		flex-wrap: wrap;
		margin-left: ${units(2)};
	}
`;

export const HeadlinedWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: ${units(35)};
	align-items: start;
`;

export const CoverageWrapper = styled.div`
	margin-top: ${units(2)};
	// Treat button as though it had no left padding since we don't support that in the design system, yet.
	margin-left: -${units(2)};
`;

export const BottomHeaderContainer = styled.div`
	gap: ${units(2)};
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

export const SearchWrapper = styled.div`
	flex-grow: 1;
	max-width: ${units(45)};
`;

export const ErrorDisplay = styled.div`
	margin: ${units(2)};
`;

export const UsersTableCard = styled(Card).attrs({ noPadding: true })`
	border-top-right-radius: 0px;
	border-top-left-radius: 0px;
	margin-top: 0px;
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${units(1)};
`;

export const Headline = styled.span`
	${fontSize(FontSizes.sm)};
	${fontWeight(FontWeights.Medium)};
	color: ${palette('gray5')};
	line-height: 1.5;
`;

export const InfoIcon = styled(InfoOutline)`
	width: ${units(2.5)};
	height: ${units(2.5)};
	margin-left: ${units(0.5)};

	path {
		fill: ${palette('blue3')};
	}
`;

export const ReminderEmailDate = styled(Text).attrs({
	weight: FontWeights.Medium,
	el: 'span',
	size: FontSizes.sm,
})`
	margin: ${units(0, 0, 1, 2)};
`;

export const EmailReminderCta = styled(Text)`
	&:hover {
		cursor: pointer;
	}
`;

export const MobileLabel = styled.span`
	color: ${palette('gray5')};
	text-transform: uppercase;
	white-space: nowrap;
	${fontSize(FontSizes.sm)};
`;
export const MobileDataColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;

	p {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 100%;
	}
`;

export const MobileUsersRow = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	padding: ${units(1, 2)};
	width: 100%;
	gap: ${units(2)};
`;
export const MobileUsersWrapper = styled.div`
	${MobileUsersRow}:not(:last-child) {
		border-bottom: 1px solid ${palette('gray1')};
	}
`;
