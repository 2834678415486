import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Card, FontSizes, FontWeights, FormInput, Text } from '@calm-web/design-system';
import { stringFromModelValue } from '@calm-web/use-form';

import ComplianceSelection from '@/components/pages/Account/Compliance';
import DeletePartner from '@/components/pages/Account/DeletePartner';
import DependentsDetails from '@/components/pages/Account/DependentsDetails';
import HelperDocs from '@/components/pages/Account/HelperDocs';
import IntegrationDetails from '@/components/pages/Account/IntegrationDetails';
import { integrationWasAccessCodes } from '@/components/pages/Account/IntegrationDetails/SsoConversion';
import AccountManagerTools from '@/components/pages/Account/NameAndId/AccountManagerTools';
import PartnerCategory from '@/components/pages/Account/PartnerCategory';
import PartnerChurn from '@/components/pages/Account/PartnerChurn';
import PartnerSKUDisplayName from '@/components/pages/Account/PartnerSKUDisplayName';
import ProductSKUTypes from '@/components/pages/Account/ProductSKUTypes';
import PromotionConfiguration from '@/components/pages/Account/PromotionConfiguration';
import SalesforceDetails from '@/components/pages/Account/SalesforceDetails';
import SubscriptionAccessModel from '@/components/pages/Account/SubscriptionAccessModel';
import CellTitle from '@/components/ui/CellTitle';
import { FeatureFlagNames, useFeatureFlags } from '@/hooks/api/useFeatureFlags';
import { usePermissions } from '@/hooks/auth';
import { EditHealthConfigFormProps } from '@/hooks/forms/useHealthConfigForm';
import {
	usePartnerSubmitData,
	ACCESS_CODE_DESCRIPTOR,
	EditPartnerFormProps,
} from '@/hooks/forms/usePartnerForm';
import { useSubmitExistingPartnerForm } from '@/hooks/forms/useSubmitExistingPartnerForm';
import { Partner, PartnerCategoryType, IntegrationType } from '@/types/store/reducers';
import { isCalmHealthSKU } from '@/utils/SkuUtils';
import { UserIdType } from '@/utils/UserIdType';

import EligibilityFileFormat from '../../../EligibilityFileFormat';
import HealthIntegrationType from '../../../HealthIntegrationType';
import LDU from './LDU';
import ParentSelector from './ParentSelector';
import DemoStatus from './SalesTrialStatus';
import messages from './messages';

interface ContractProps {
	formProps: EditPartnerFormProps;
	healthConfigFormProps: EditHealthConfigFormProps;
	partner?: Partner;
}

const DEPENDENTS_FEATURE_FLAG = 'b2b-dependents';
const ELIGIBILITY_FILE_FORMAT_FLAG = 'b2b-eligibility-file-format-configuration';

function getUserIdDescriptor(
	userIdDescriptor: string,
	eligibilityType: UserIdType | undefined,
	integrationType: IntegrationType,
): string | undefined {
	if (integrationType === IntegrationType.ACCESS_CODES) {
		return ACCESS_CODE_DESCRIPTOR;
	}
	if (integrationType === IntegrationType.GROUP_CODE) {
		return 'group code';
	}
	if (eligibilityType === UserIdType.Email) {
		return 'Email';
	}
	if (!userIdDescriptor) {
		return 'other';
	}
	return userIdDescriptor;
}

const CalmAdmin: FC<ContractProps> = ({ partner, formProps, healthConfigFormProps }) => {
	const [hasValidPermissions, actions] = usePermissions();
	const { formatMessage } = useIntl();
	const partnerId = partner?.id;
	const requiredPermissions = partnerId ? [actions.UPDATE] : [actions.CREATE];
	const shouldShowPartnerCategory = hasValidPermissions('category', requiredPermissions);
	const {
		data: flagValues,
		error: flagError,
		loading: flagLoading,
	} = useFeatureFlags(
		DEPENDENTS_FEATURE_FLAG,
		FeatureFlagNames.B2B_PARENT_PARTNER,
		ELIGIBILITY_FILE_FORMAT_FLAG,
	);
	const flagsLoaded = !flagLoading && !flagError && flagValues;

	const { vouchedPlanSku, getPartnerSubmitData, partnerIsOnUnchangeableSku } = usePartnerSubmitData(
		formProps,
		partner,
	);
	const [submitExistingPartnerForm, { loading }] = useSubmitExistingPartnerForm(formProps);

	const isCalmHealth = useMemo(() => {
		return isCalmHealthSKU(vouchedPlanSku);
	}, [vouchedPlanSku]);

	const shouldShowDelete = partnerId && hasValidPermissions('id', [actions.DELETE]);
	const shouldShowCompliance =
		hasValidPermissions('is_hipaa_compliant', requiredPermissions) && !isCalmHealth;
	const shouldShowPartnerChurn = partnerId && hasValidPermissions('has_churned', [actions.UPDATE]);

	const isDTCLeadGenPartnership = formProps.model.category === PartnerCategoryType.D2C_LEAD_GEN_PARTNERSHIP;

	const shouldShowDependents =
		flagsLoaded &&
		flagValues &&
		flagValues[DEPENDENTS_FEATURE_FLAG] === true &&
		!isDTCLeadGenPartnership &&
		!isCalmHealth;
	const shouldShowParentSelector = flagValues && flagValues[FeatureFlagNames.B2B_PARENT_PARTNER] === true;
	const shouldShowEligibilityFileFormat =
		flagsLoaded &&
		flagValues[ELIGIBILITY_FILE_FORMAT_FLAG] === true &&
		isCalmHealth &&
		healthConfigFormProps.model.integration_type === IntegrationType.ELIGIBILITY_FILE;

	const shouldHideSkuChangingCards =
		!hasValidPermissions('vouched_plan_sku', requiredPermissions) || partnerIsOnUnchangeableSku;

	const lduProps = formProps.bindWithErrorProps('isLdu', 'radio');
	const salesTrialProps = formProps.bindWithErrorProps('isSalesTrial', 'radio');
	const shouldShowIdentifier = hasValidPermissions('user_id_descriptor', requiredPermissions);

	const onClickChangeIntegrationType = async (newIntegrationType: IntegrationType): Promise<void> => {
		if (!partner) return;

		// These two integration types both use an eligibility list under the hood
		const willSupportEligibilityList = [
			IntegrationType.ACCESS_CODES,
			IntegrationType.ELIGIBILITY_FILE,
		].includes(newIntegrationType);
		// SSO, while not being an eligibility list integration type, does allow
		// continued use of the eligibility list during the transition period
		const shouldEditEligibilityListSupport = newIntegrationType !== IntegrationType.SSO;

		await submitExistingPartnerForm(
			{
				integration_type: newIntegrationType,
				...(shouldEditEligibilityListSupport
					? { supports_eligibility_list: willSupportEligibilityList }
					: {}),
				...(willSupportEligibilityList
					? {
							user_id_descriptor: getUserIdDescriptor(
								partner?.user_id_descriptor ?? '',
								UserIdType.Email,
								newIntegrationType,
							),
					  }
					: {}),
			},
			partner,
		);
		formProps.setProperty('integrationType', newIntegrationType);
	};

	async function onClickFinishSsoTransition(): Promise<void> {
		if (!partner) return;

		await submitExistingPartnerForm(
			{
				vouched_plan_sku: vouchedPlanSku,
				supports_eligibility_list: false,
			},
			partner,
		);
	}

	async function onClickCancelSsoTransition(): Promise<void> {
		if (!partner) return;
		const newIntegrationType = integrationWasAccessCodes(formProps)
			? IntegrationType.ACCESS_CODES
			: IntegrationType.ELIGIBILITY_FILE;

		await submitExistingPartnerForm({ integration_type: newIntegrationType }, partner);
		formProps.setProperty('integrationType', newIntegrationType);
	}

	const onSalesforceIdSuccess = async (b2bAccountId: string): Promise<void> => {
		if (!b2bAccountId) throw new Error('Missing b2bAccountId value');

		formProps.setProperty('b2bAccountId', b2bAccountId);

		if (partner?.id) {
			const data = getPartnerSubmitData();
			await submitExistingPartnerForm({ ...data, b2b_account_id: b2bAccountId }, partner);
		}
	};
	const onSalesforceIdDisconnect = async (): Promise<void> => {
		formProps.setProperty('b2bAccountId', '');

		if (partner?.id) {
			const data = getPartnerSubmitData();
			await submitExistingPartnerForm({ ...data, b2b_account_id: '' }, partner);
		}
	};

	return (
		<>
			<section>
				<Text el="h2" size={FontSizes.xl} weight={FontWeights.Regular} color="gray7">
					{formatMessage(messages.adminTitle)}
				</Text>
				{partner && <PartnerSKUDisplayName partnerId={partner.id} vouchedPlanSku={vouchedPlanSku} />}
				{!shouldHideSkuChangingCards && (
					<ProductSKUTypes disabled={isDTCLeadGenPartnership} formProps={formProps} isEdit={!!partnerId} />
				)}
				{!shouldHideSkuChangingCards && <SubscriptionAccessModel formProps={formProps} />}
				{isCalmHealth ? null : <HelperDocs />}
				{shouldShowPartnerCategory && <PartnerCategory formProps={formProps} />}
				{shouldShowDependents && <DependentsDetails formProps={formProps} partner={partner} />}
				<DemoStatus salesTrialProps={salesTrialProps} isEdit={!!partnerId} />
				{isDTCLeadGenPartnership && <PromotionConfiguration isEdit={!!partnerId} formProps={formProps} />}
				<SalesforceDetails
					b2bAccountId={stringFromModelValue(formProps.model.b2bAccountId)}
					onSuccess={onSalesforceIdSuccess}
					onDisconnect={onSalesforceIdDisconnect}
				/>
				{isCalmHealth ? (
					<HealthIntegrationType
						salesTrialProps={salesTrialProps}
						isEdit={!!partnerId}
						healthConfigFormProps={healthConfigFormProps}
					/>
				) : (
					<IntegrationDetails
						shouldShowIdentifier={shouldShowIdentifier}
						isEdit={!!partnerId}
						formProps={formProps}
						isAccountManager
						partner={partner}
						integrationType={partner?.integration_type}
						onClickChangeIntegrationType={onClickChangeIntegrationType}
						onClickFinishSsoTransition={onClickFinishSsoTransition}
						onClickCancelSsoTransition={onClickCancelSsoTransition}
						isLoading={loading}
						disabled={isDTCLeadGenPartnership}
					/>
				)}
				{shouldShowEligibilityFileFormat && (
					<EligibilityFileFormat formProps={healthConfigFormProps} isEdit={!!partnerId} />
				)}
				{partner && (
					<Card>
						<CellTitle>Calm Partner Client ID</CellTitle>
						<AccountManagerTools partner={partner} />
					</Card>
				)}
				{isCalmHealth && (
					<Card>
						<CellTitle showAdminFlag>Calm Health Client ID</CellTitle>
						<FormInput
							label="Calm Health Client ID"
							{...healthConfigFormProps.bindWithErrorProps('client_id', 'text')}
						/>
					</Card>
				)}
				{shouldShowCompliance && <ComplianceSelection formProps={formProps} />}
				{!isCalmHealth && <LDU lduProps={lduProps} isEdit={!!partnerId} />}
				{shouldShowParentSelector ? <ParentSelector partner={partner} formProps={formProps} /> : null}
			</section>
			<section>
				{shouldShowPartnerChurn && <PartnerChurn formProps={formProps} />}
				{shouldShowDelete && <DeletePartner partnerName={partner?.name} partnerId={partnerId} />}
			</section>
		</>
	);
};

export default CalmAdmin;
