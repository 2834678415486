import debounce from 'lodash/debounce';
import { ReactElement, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { FontSizes, FormInput, TextButton } from '@calm-web/design-system';
import { OnChange, stringFromModelValue } from '@calm-web/use-form';

import { EmailTemplate, executeCopyTemplate } from '@/components/ui/LaunchTemplates';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { SendApiRequest, useApi } from '@/hooks/api';
import { useIntegrationType } from '@/hooks/api/useIntegrationType';
import { PartnerMilestoneType, useRecordMilestone } from '@/hooks/api/useMilestones';
import { usePermissions } from '@/hooks/auth';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import useQrCode from '@/hooks/useQrCode';
import { setBannerMessage } from '@/store/actions';
import { IntegrationType, Partner } from '@/types/store/reducers';
import { SkuToConfig } from '@/utils/SkuToConfig';
import { calmLogger } from '@/utils/calmLogger';
import { createB2BPartnerSubscribeUrlPreview } from '@/utils/url';

import SsoSignupUrl from './SsoSignupUrl';
import SubscribeUrl from './SubscribeUrl';
import messages from './messages';
import { InputWrapper, QrDownloadLink, CopyTemplateSection, HideTemplate } from './styles';

const MIN_SLUG_LENGTH = 2;

function LaunchTemplate({ partner }: { partner: Partner }): ReactElement {
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();
	const [recordMilestone] = useRecordMilestone();
	const { data: integrationType } = useIntegrationType(partner.id);

	const emailRef = useRef<HTMLDivElement>(null);
	const isSSO = integrationType === IntegrationType.SSO;
	const nonSsoSubscribeUrl =
		partner.redemption_url ??
		createB2BPartnerSubscribeUrlPreview({
			slug: partner.slug,
			skuType: SkuToConfig[partner.vouched_plan_sku].productSKUType,
		});
	const signupLink = isSSO ? partner?.web_renew_url : nonSsoSubscribeUrl;

	const copyEmailTemplate = async (): Promise<void> => {
		logEvent('Partner Portal : Account : Copy Launch Email Template');

		const emailTemplate = emailRef.current;
		if (emailTemplate) {
			try {
				await executeCopyTemplate(emailTemplate);

				dispatch(
					setBannerMessage({
						message: 'Email Template copied to clipboard',
						isError: false,
						flash: true,
					}),
				);
				await recordMilestone({
					eventName: PartnerMilestoneType.LAUNCH_ANNOUNCEMENT_SENT,
					partnerId: partner.id,
				});
			} catch (error) {
				dispatch(
					setBannerMessage({
						message: 'Unable to copy to the clipboard at this time',
						isError: true,
						flash: true,
					}),
				);
			}
		}
	};

	return (
		<>
			<CopyTemplateSection>
				{window.navigator.clipboard && (
					<TextButton size={FontSizes.sm} hideUnderline onClick={copyEmailTemplate}>
						Copy Announcement Template
					</TextButton>
				)}
			</CopyTemplateSection>
			<HideTemplate aria-hidden>
				<EmailTemplate
					hideSendOption
					emailRef={emailRef}
					signupLink={signupLink}
					showMessageSection={false}
					showOldTemplate={false}
				/>
			</HideTemplate>
		</>
	);
}

async function getRecommendedPartnerSlug(
	longName: string,
	hasManuallyChangedSlug: boolean,
	apiRequest: SendApiRequest,
	updateSlug: (slug: string) => void,
): Promise<void> {
	try {
		if (!hasManuallyChangedSlug && longName.length >= MIN_SLUG_LENGTH) {
			const {
				data: { suggested_slug },
			} = await apiRequest({
				endpoint: `b2b/partners/suggested-slug?partner_name=${longName}`,
				method: 'GET',
			});
			updateSlug(suggested_slug);
		}
	} catch {
		// Errors can be noisy here, just fail silently
	}
}

const RECOMMENDED_SLUG_DEBOUNCE = process.env.NODE_ENV === 'test' ? 0 : 250;
const debouncedRecommendedSlug = debounce(getRecommendedPartnerSlug, RECOMMENDED_SLUG_DEBOUNCE);

interface Props {
	formProps: EditPartnerFormProps;
	isEdit: boolean;
	isReadOnly?: boolean;
	isDisabled?: boolean;
	partner?: Partner;
}

export default function PartnerDetails({
	formProps,
	isEdit = false,
	isReadOnly = false,
	isDisabled = false,
	partner,
}: Props): ReactElement {
	const { formatMessage } = useIntl();
	const apiRequest = useApi();
	const { logEvent } = useAnalytics();
	const [hasManuallyChangedSlug, setHasManuallyChangedSlug] = useState(false);
	const [hasValidPermissions, actions] = usePermissions();
	const newQrDataImage = useQrCode(partner);

	const requiredPermissions = isEdit ? [actions.UPDATE] : [actions.CREATE];

	const onPartnerLongNameChange =
		(onChange: OnChange): OnChange =>
		e => {
			const longName = e.target.value;
			onChange(e);
			if (isEdit) return;
			if (longName.length < MIN_SLUG_LENGTH) {
				formProps.setProperty('partnerSlug', '');
			}
			debouncedRecommendedSlug(longName, hasManuallyChangedSlug, apiRequest, (value: string) =>
				formProps.setProperty('partnerSlug', value),
			)?.catch(err => calmLogger.error('Error in PartnerDetails debouncedRecommendedSlug', {}, err));
		};

	// Mutate current event in order to keep all other properties present
	const onPartnerSlugChange =
		(onChange: OnChange): OnChange =>
		e => {
			setHasManuallyChangedSlug(true);
			e.target.value = e.target.value.toLowerCase().replace(/_/g, '-');
			onChange(e);
		};

	const partnerLongNameInputProps = formProps.bindWithErrorProps('partnerLongName', 'text');
	const partnerSlugInputProps = formProps.bindWithErrorProps('partnerSlug', 'text');

	const onQrCodeDownload = (): void => {
		const a = document.createElement('a');
		a.href = newQrDataImage;
		a.download = 'download.png';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		logEvent('Get Set Up : Download QR Code', {
			type: 'EF or Access Codes',
			triggeredFrom: 'Account Page : Account Details',
		});
	};

	return (
		<InputWrapper>
			<FormInput
				{...partnerLongNameInputProps}
				data-testid="partner-name-input"
				disabled={isDisabled || !hasValidPermissions('name', requiredPermissions)}
				onChange={onPartnerLongNameChange(partnerLongNameInputProps.onChange)}
				label={formatMessage(messages.longNamePlaceholder)}
				readOnly={isReadOnly}
			/>
			<FormInput
				{...partnerSlugInputProps}
				data-testid="partner-slug-input"
				disabled={isDisabled || !hasValidPermissions('slug', requiredPermissions)}
				onChange={onPartnerSlugChange(partnerSlugInputProps.onChange)}
				label="Identifier for Subscribe URL"
				readOnly={isReadOnly}
			/>
			{isEdit && (
				<SubscribeUrl
					skuType={stringFromModelValue(formProps.model.productSKUType) ?? ''}
					partnerSlug={stringFromModelValue(formProps.model.partnerSlug) ?? ''}
					isFinalized={isEdit || isReadOnly}
				/>
			)}
			<SsoSignupUrl webRenewUrl={partner?.web_renew_url} />
			{partner && (
				<QrDownloadLink onClick={onQrCodeDownload} size={FontSizes.sm} hideUnderline>
					Download Signup QR Code
				</QrDownloadLink>
			)}
			{partner && <LaunchTemplate partner={partner} />}
		</InputWrapper>
	);
}
