import { useDispatch } from 'react-redux';
import useSWR from 'swr';

import { useApi } from '@/hooks/api';
import { useUser } from '@/hooks/store';
import { setBannerMessage } from '@/store/actions';
import { Admin } from '@/types/admin';
import { partnerRoles } from '@/utils/RBAC';
import { isCalmError } from '@/utils/apiRequest/errors';

import { useAnalytics } from '../analytics/useAnalytics';
import { ApiResponse } from './types';

interface AdminResponse extends ApiResponse<Array<Admin>> {
	promoteToAdminUser: (email: string, id: string) => Promise<void>;
	removeAdminUser: (email: string, id: string) => Promise<void>;
	updatePHIAuthorization: (email: string, id: string, authorizePHI: boolean) => Promise<void>;
	updateAdmins: (data: Array<Admin>, shouldRevalidate?: boolean) => void;
	resetAdminMFA: (email: string) => Promise<void>;
}

export function usePartnerAdmin(partnerId: string): AdminResponse {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();
	const { user } = useUser();
	const isAdmin = user?.accessPolicy?.isAdmin;
	const allowedPartners = user?.accessPolicy?.allowedPartners;
	const isAllowedPartner = allowedPartners?.includes(partnerId);
	const isAbleToFetchPartner = isAdmin || isAllowedPartner;

	const {
		data: admins,
		error,
		mutate,
	} = useSWR(
		`partnerportal/users/list/?partner_id=${partnerId}`,
		async endpoint => {
			try {
				if (!isAbleToFetchPartner) {
					throw new Error('Not able to fetch partner admins');
				}

				const _response = await apiRequest<{ users: Array<Admin> }>({
					endpoint,
				});
				if (!_response.data) {
					throw new Error('Not able to fetch partner admins');
				}
				// API will start returning data on a nested key, so we check the nested place first
				return _response?.data?.users ?? (_response?.data as unknown as Array<Admin>);
			} catch (responseError) {
				dispatch(
					setBannerMessage({
						message: `Failed to retrieve partner admins for: ${partnerId}`,
						isError: true,
						flash: true,
					}),
				);
				throw responseError;
			}
		},
		{ errorRetryCount: 0 },
	);

	// Revalidating would most likely just hide a bug at the expense of an unnecessary network call
	// Default to false
	function updateAdmins(
		data: Array<Admin> | undefined,
		shouldRevalidate = false,
	): Promise<Array<Admin> | undefined> {
		return mutate(data, shouldRevalidate);
	}

	async function removeAdminUser(email: string, partnerIdToRemove: string): Promise<void> {
		try {
			await apiRequest({
				endpoint: 'partnerportal/users',
				method: 'DELETE',
				body: {
					partner_id: partnerIdToRemove,
					email,
				},
			});
			dispatch(
				setBannerMessage({
					message: 'Successfully removed partner admin',
					isError: false,
					flash: true,
				}),
			);
			const newAdmins = admins?.filter((admin: Admin) => admin.email !== email);
			await updateAdmins(newAdmins, false);
		} catch (err) {
			if (isCalmError(err) && err?.data?.error?.code === 'invalid_email') {
				// Shouldn't really happen, but we'll keep it here to help with issues
				// while developing/debugging
				dispatch(
					setBannerMessage({
						message: `Unable to find admin user ${email}`,
						isError: true,
						flash: true,
					}),
				);
			} else {
				dispatch(
					setBannerMessage({
						message: 'Failed to remove partner admin.',
						isError: true,
						flash: true,
					}),
				);
			}
		}
	}

	async function promoteToAdminUser(email: string, partnerId: string): Promise<void> {
		try {
			const promoteResponse = await apiRequest({
				endpoint: `partnerportal/${partnerId}/admin`,
				method: 'PATCH',
				body: {
					email,
					role: partnerRoles.partnerAdmin,
				},
			});
			const newAdmin = promoteResponse.data;
			dispatch(
				setBannerMessage({
					message: `Successfully promoted ${email} to partner admin`,
					isError: false,
					flash: true,
				}),
			);
			const newAdmins = admins?.filter((admin: Admin) => admin.email !== email) ?? [];
			await updateAdmins([...newAdmins, newAdmin], false);
		} catch (err) {
			if (isCalmError(err) && err?.data?.error?.code === 'invalid_email') {
				// Shouldn't really happen, but we'll keep it here to help with issues
				// while developing/debugging
				dispatch(
					setBannerMessage({
						message: `Unable to find admin user ${email}`,
						isError: true,
						flash: true,
					}),
				);
			} else {
				dispatch(
					setBannerMessage({
						message: 'Failed to promote to partner admin.',
						isError: true,
						flash: true,
					}),
				);
			}
		}
	}

	async function updatePHIAuthorization(
		email: string,
		partnerId: string,
		authorizePHI: boolean,
	): Promise<void> {
		try {
			const promoteResponse = await apiRequest({
				endpoint: `partnerportal/${partnerId}/admin`,
				method: 'PATCH',
				body: {
					email,
					phi_authorized: authorizePHI,
				},
			});
			const newAdmin = promoteResponse.data;
			logEvent('Partner Portal : Admin : Update PHI Authorization', { email, partnerId, authorizePHI });
			dispatch(
				setBannerMessage({
					message: `Successfully updated ${email} Reporting Viewing Permissions`,
					isError: false,
					flash: true,
				}),
			);
			const newAdmins = admins?.filter((admin: Admin) => admin.email !== email) ?? [];
			await updateAdmins([...newAdmins, newAdmin], false);
		} catch (err) {
			if (isCalmError(err) && err?.data?.error?.code === 'invalid_email') {
				// Shouldn't really happen, but we'll keep it here to help with issues
				// while developing/debugging
				dispatch(
					setBannerMessage({
						message: `Unable to find admin user ${email}`,
						isError: true,
						flash: true,
					}),
				);
			} else {
				dispatch(
					setBannerMessage({
						message: `Failed to update admin Reporting Permissions.`,
						isError: true,
						flash: true,
					}),
				);
			}
		}
	}

	async function resetAdminMFA(email: string): Promise<void> {
		try {
			await apiRequest({
				endpoint: `partnerportal/users/reset_mfa`,
				method: 'DELETE',
				body: {
					email,
				},
			});
			logEvent('Partner Portal : Admin : Reset MFA', { email });
			dispatch(
				setBannerMessage({
					message: `Successfully reset MFA for ${email}.`,
					isError: false,
					flash: true,
				}),
			);
		} catch (err) {
			dispatch(
				setBannerMessage({
					message: `Failed to reset MFA for ${email}.`,
					isError: true,
					flash: true,
				}),
			);
		}
	}

	return {
		data: admins,
		error,
		loading: !admins && !error,
		removeAdminUser,
		promoteToAdminUser,
		updatePHIAuthorization,
		updateAdmins,
		resetAdminMFA,
	};
}
