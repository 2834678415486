import { ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import BreatheBubble from '@/components/ui/BreatheBubble';
import { useTableauReport } from '@/hooks/api/reporting/useTableauReport';
import { Partner } from '@/types/store/reducers';

import { Container } from '../styles';
import messages from './messages';
import { HiddenDiv, BreatheWrapper, LoadingText } from './styles';

export default function CHReporting({
	partner,
	preview = false,
}: {
	partner: Partner;
	preview?: boolean;
}): ReactElement {
	const { data } = useTableauReport({ partnerId: partner.id, preview });
	const [isInitialized, setIsInitialized] = useState(false);
	const [isError, setIsError] = useState(false);
	const { formatMessage } = useIntl();

	useEffect(() => {
		if (data?.url && data?.token) {
			const setupTableau = async (): Promise<void> => {
				const { TableauViz, Toolbar, TableauEventType } = await import('@tableau/embedding-api');
				const viz = new TableauViz();
				viz.src = data.url;
				viz.hideTabs = true;
				viz.toolbar = Toolbar.Hidden;
				viz.token = data.token;
				viz.addEventListener(TableauEventType.VizLoadError, () => {
					setIsError(true);
					setIsInitialized(true);
				});
				viz.addEventListener(TableauEventType.FirstInteractive, () => {
					setIsInitialized(true);
				});

				const element = document.getElementById('tableau-viz');
				if (element) {
					element.innerHTML = '';
					element.appendChild(viz);
				}
			};
			if (!isInitialized) {
				setupTableau().catch(() => {}); // no-op
			}
		}
	}, [data?.url, data?.token, isInitialized]);

	return (
		<Container>
			<InitialPageAnalytics />
			{!isInitialized && (
				<div>
					<LoadingText>
						{formatMessage(messages.loadingMessage)}
						<br />
						{formatMessage(messages.breatheMessage)}
					</LoadingText>
					<BreatheWrapper>
						<BreatheBubble textColor="white" />
					</BreatheWrapper>
				</div>
			)}
			{isError && <div>There was an error loading your report. Please reach out to support for help.</div>}
			<HiddenDiv $hidden={!isInitialized} id="tableau-viz"></HiddenDiv>
		</Container>
	);
}
