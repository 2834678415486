import { ReactElement, useEffect } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { Card, FormInput } from '@calm-web/design-system';
import { ModelValue, stringFromModelValue } from '@calm-web/use-form';

import CellTitle from '@/components/ui/CellTitle';
import type { EditPartnerFormProps } from '@/hooks/forms/usePartnerForm';
import { StripeCouponDuration, TrialDuration } from '@/types/store/reducers';
import { Duration } from '@/utils/SkuEnums';
import { hasDatePassed } from '@/utils/helpers';

import messages from './messages';
import {
	DurationInMonthsInput,
	OfferSubtitle,
	OfferTerms,
	PartnershipConfigColumn,
	PromotionLabel,
	PromotionColumnWrapper,
} from './styles';

export const promotionTrialLengthOptions: {
	value: TrialDuration | string;
	label: MessageDescriptor;
}[] = [
	{
		value: TrialDuration.NULL,
		label: messages.promotionNoTrial,
	},
	{
		value: TrialDuration.SEVEN_DAYS,
		label: messages.promotionTrialDurationSevenDays,
	},
	{
		value: TrialDuration.FOURTEEN_DAYS,
		label: messages.promotionTrialDurationFourteenDays,
	},
	{
		value: TrialDuration.ONE_MONTH,
		label: messages.promotionTrialDurationOneMonth,
	},
	{
		value: TrialDuration.TWO_MONTHS,
		label: messages.promotionTrialDurationTwoMonths,
	},
	{
		value: TrialDuration.THREE_MONTHS,
		label: messages.promotionTrialDurationThreeMonths,
	},
	{
		value: TrialDuration.FOUR_MONTHS,
		label: messages.promotionTrialDurationFourMonths,
	},
	{
		value: TrialDuration.SIX_MONTHS,
		label: messages.promotionTrialDurationSixMonths,
	},
	{
		value: TrialDuration.TWELVE_MONTHS,
		label: messages.promotionTrialDurationTwelveMonths,
	},
];

export const durationLengthOptions: {
	value: TrialDuration | string;
	label: MessageDescriptor;
}[] = [
	{
		value: StripeCouponDuration.ONCE,
		label: messages.couponDurationOnce,
	},
	{
		value: StripeCouponDuration.REPEATING,
		label: messages.couponDurationRepeating,
	},
	{
		value: StripeCouponDuration.FOREVER,
		label: messages.couponDurationForever,
	},
];

function PromotionConfiguration({
	isEdit = false,
	formProps,
}: {
	isEdit: boolean;
	formProps: EditPartnerFormProps;
}): ReactElement {
	const { formatMessage } = useIntl();
	const {
		model: { duration_type },
	} = formProps;

	function getTrialDurationLabel(isoDuration: ModelValue | undefined): string {
		const durationLabel = promotionTrialLengthOptions.find(
			lengthOption => lengthOption.value === isoDuration,
		);
		return formatMessage(durationLabel?.label ?? promotionTrialLengthOptions[0].label).toLowerCase();
	}

	function getSubDurationLabel(duration: string): string {
		if (formProps.model.duration_type === StripeCouponDuration.FOREVER) {
			return formatMessage(messages.durationForever, {
				duration: duration === Duration.OneMonth ? 'month' : 'year',
			}) as string;
		}

		if (duration === Duration.OneMonth) {
			return formatMessage(messages.durationMonth, {
				quantity: parseInt(formProps?.model?.duration_in_months as string) || 1,
			}) as string;
		}

		const durationInMonths = formProps?.model?.duration_in_months || 1;

		return formatMessage(messages.durationYear, {
			quantity: Math.ceil((durationInMonths as number) / 12),
		}) as string;
	}

	useEffect(() => {
		if (duration_type !== StripeCouponDuration.REPEATING) {
			formProps.setProperty('duration_in_months', '1');
		}
	}, [duration_type, formProps]);

	return (
		<Card>
			<CellTitle showAdminFlag>{formatMessage(messages.title)}</CellTitle>
			<PromotionColumnWrapper>
				<PartnershipConfigColumn>
					<PromotionLabel>{formatMessage(messages.extendedTrialTypeLabel)}</PromotionLabel>
					<select
						data-testid="promotion-configuration"
						{...formProps.bindInput('trialDuration', 'select')}
						id="trialDuration"
					>
						{promotionTrialLengthOptions.map(({ value, label }) => {
							return (
								<option key={value} value={value}>
									{formatMessage(label)}
								</option>
							);
						})}
					</select>
				</PartnershipConfigColumn>
				<PartnershipConfigColumn>
					<PromotionLabel>{formatMessage(messages.couponDurationTypeLabel)}</PromotionLabel>
					<div>
						<select
							data-testid="promotion-configuration-duration"
							{...formProps.bindInput('duration_type', 'select')}
							id="couponDuration"
						>
							{durationLengthOptions.map(({ value, label }) => {
								return (
									<option key={value} value={value}>
										{formatMessage(label)}
									</option>
								);
							})}
						</select>
					</div>
					{formProps.model.duration_type === StripeCouponDuration.REPEATING && (
						<DurationInMonthsInput>
							<FormInput
								{...formProps.bindWithErrorProps('duration_in_months', 'number')}
								data-testid="promotion-coupon-duration-in-months-input"
								label={formatMessage(messages.couponDurationInMonthsInputLabel)}
							/>
						</DurationInMonthsInput>
					)}
				</PartnershipConfigColumn>
				<PartnershipConfigColumn>
					<PromotionLabel>{formatMessage(messages.percentOffTypeLabel)}</PromotionLabel>
					<FormInput
						{...formProps.bindWithErrorProps('percentOff', 'number')}
						data-testid="promotion-percent-off-input"
						label={formatMessage(messages.percentOffInputLabel)}
						readOnly={isEdit && hasDatePassed(formProps.model.contractStartDate as string)}
					/>
				</PartnershipConfigColumn>
			</PromotionColumnWrapper>
			<OfferSubtitle>
				{formProps.model.trialDuration !== 'null'
					? formatMessage(messages.offerDetails, {
							percentOff: stringFromModelValue(formProps.model.percentOff),
							trialDuration: getTrialDurationLabel(formProps.model.trialDuration),
							subDuration: getSubDurationLabel(formProps.model.duration as string),
					  })
					: formatMessage(messages.offerDetailsNoTrial, {
							percentOff: stringFromModelValue(formProps.model.percentOff),
							subDuration: getSubDurationLabel(formProps.model.duration as string),
					  })}
			</OfferSubtitle>
			<OfferTerms>
				<PromotionLabel>{formatMessage(messages.partnerSpecificTermsLabel)}</PromotionLabel>
				<FormInput
					{...formProps.bindWithErrorProps('partnerSpecificTerms', 'text')}
					data-testid="promotion-partner-terms-input"
					label={formatMessage(messages.partnerTermsInputPlaceholder)}
				/>
			</OfferTerms>
		</Card>
	);
}

export default PromotionConfiguration;
